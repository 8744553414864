exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-careers-open-positions-tsx": () => import("./../../../src/pages/careers/open-positions.tsx" /* webpackChunkName: "component---src-pages-careers-open-positions-tsx" */),
  "component---src-pages-company-about-us-tsx": () => import("./../../../src/pages/company/about-us.tsx" /* webpackChunkName: "component---src-pages-company-about-us-tsx" */),
  "component---src-pages-company-contact-us-tsx": () => import("./../../../src/pages/company/contact-us.tsx" /* webpackChunkName: "component---src-pages-company-contact-us-tsx" */),
  "component---src-pages-company-impressum-tsx": () => import("./../../../src/pages/company/impressum.tsx" /* webpackChunkName: "component---src-pages-company-impressum-tsx" */),
  "component---src-pages-data-protection-cookie-policy-tsx": () => import("./../../../src/pages/data-protection/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-data-protection-cookie-policy-tsx" */),
  "component---src-pages-data-protection-privacy-policy-tsx": () => import("./../../../src/pages/data-protection/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-data-protection-privacy-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-quality-management-quality-policy-tsx": () => import("./../../../src/pages/quality-management/quality-policy.tsx" /* webpackChunkName: "component---src-pages-quality-management-quality-policy-tsx" */),
  "component---src-pages-solutions-services-tsx": () => import("./../../../src/pages/solutions/services.tsx" /* webpackChunkName: "component---src-pages-solutions-services-tsx" */)
}

